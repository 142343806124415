import React from 'react'
import { config, useSpring } from 'react-spring'
import Layout from '../components/layout'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'

const About = () => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  return (
    <Layout>
      <SEO title="Thanks! | Dhamen photography" desc="Hi. I'm Dhamen, a Tunisian Photographer living in Paris." />
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 13]}>
      <h1>Thank you for getting in touch!</h1>
      <p>I appreciate you contacting me. I'll get back in touch with you soon!</p>
      <p>Have a great day!</p>
      <p>
        You can follow me on <a target="_blank" href="https://www.instagram.com/dhamen.photos/">Instagram</a> or see{' '}
        <a href="/projects">some of my projects</a>.
      </p>
      </AnimatedBox>
    </Layout>
  )
}

export default About
